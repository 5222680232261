import { Button, Card, HorizontalStack, Popover, VerticalStack } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { FilterMajor } from "@shopify/polaris-icons";
import { NavigationFilterDate } from "./Date";
import { NavigationFilterAmount } from "./Amount";
import { NavigationFilterStatus } from "./Status";
import { NavigationFilterSellingPlan } from "./SellingPlan";
import { NavigationFilterProducts } from "./Products";
import { OpenedSubfilterContext } from "./openedSubfilterContext";
import { SubscriptionsTableFilters } from "../../../store/filters";
import { SubscriptionsTableAccess } from "../../../store";

export const NavigationFilters = () => {
  const actions = SubscriptionsTableAccess.useActions();
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [openedSubfilter, setOpenedSubfilter] = useState<SubscriptionsTableFilters.Filter | null>(null);

  useEffect(() => {
    if (!openFilterPopover) {
      setOpenedSubfilter(null);
    }
  }, [openFilterPopover]);

  const clearAllFilters = useCallback(() => {
    setOpenedSubfilter(null);
    actions.filter.reset();
  }, []);

  const resetOpenedSubfilter = useCallback(() => {
    if (openedSubfilter) {
      setOpenedSubfilter(null);
    }
  }, [openedSubfilter]);

  return (
    <OpenedSubfilterContext.Provider
      value={{
        filterType: openedSubfilter,
        setFilter: setOpenedSubfilter,
      }}
    >
      <Popover
        fluidContent
        preventCloseOnChildOverlayClick
        active={openFilterPopover}
        activator={
          <Button icon={FilterMajor} onClick={() => setOpenFilterPopover(!openFilterPopover)}>
            Filter
          </Button>
        }
        onClose={() => setOpenFilterPopover(false)}
      >
        <div onClick={resetOpenedSubfilter}>
          <Card>
            <VerticalStack gap="2">
              <NavigationFilterDate dateFilter={"upcomingOrderDate"} />
              <NavigationFilterAmount />
              <NavigationFilterProducts />
              <NavigationFilterStatus />
              <NavigationFilterDate dateFilter={"createdDate"} />
              <NavigationFilterSellingPlan />
              <HorizontalStack align="end">
                <Button plain onClick={clearAllFilters}>
                  Clear all
                </Button>
              </HorizontalStack>
            </VerticalStack>
          </Card>
        </div>
      </Popover>
    </OpenedSubfilterContext.Provider>
  );
};
