import { useMemo } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { ELEMENT_NOT_FOUND } from "@smartrr/shared/constants";
import { SubscriptionsTableSorts } from "../../../store/sorts";
import { IndexTableSort } from "..";

export const useIndexTableHeadingSortInfo = (): IndexTableSort => {
  const selectedColumns = SubscriptionsTableAccess.useSelectedColumn();
  const selectedSort = SubscriptionsTableAccess.useSelectedSort();
  const tableActions = SubscriptionsTableAccess.useActions();

  const enabledColumns = useMemo(() => {
    return selectedColumns.filter(c => c.value);
  }, [selectedColumns]);

  return useMemo(() => {
    const currentSortColumnIndex = enabledColumns.findIndex(c => c.columnType === selectedSort.sortType);

    return {
      currentSortColumn: currentSortColumnIndex === ELEMENT_NOT_FOUND ? undefined : currentSortColumnIndex,
      direction: selectedSort.value === "ASC" ? "ascending" : "descending",
      onSort(headingIndex, direction) {
        const chosenColumn = enabledColumns[headingIndex];
        if (!chosenColumn) {
          return;
        }

        const sortType = SubscriptionsTableSorts.schema.safeParse(chosenColumn.columnType);

        if (sortType.success) {
          tableActions.sort.select({
            sortType: SubscriptionsTableSorts.SortInfo[sortType.data].name,
            value: direction === "ascending" ? "ASC" : "DESC",
          });
        }
      },
      sortableColumns: enabledColumns.map(c => !!SubscriptionsTableSorts.SortInfo[c.columnType]),
    };
  }, [enabledColumns, selectedSort]);
};
