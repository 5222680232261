import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { SubscriptionsTableAccess } from "../../../store";
import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { generateQueryFromStore } from "../../../store/query";
import { serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { IFailedSubscriptionWithReason } from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";
import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { useBulkActionErrorModal } from "../Error";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useCallback, useContext } from "react";
import { BulkActionCompletedContext } from "../../Table/bulkActionCompletedContext";

export const useCancelConfirmationModal = createSmartrrModal<{}>();

export const CancelConfirmationModal = () => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const tableActions = SubscriptionsTableAccess.useActions();
  const { isModalOpen, closeModal, modalPayload } = useCancelConfirmationModal();
  const openErrorModal = useBulkActionErrorModal(state => state.openModal);
  const { addToast } = useToast();
  const bulkActionCompleted = useContext(BulkActionCompletedContext);

  const handleCancelation = useCallback(() => {
    tableActions.data.update(async (currentData, selectedFilters, selectedSort, pagination) => {
      closeModal();
      const query = generateQueryFromStore(selectedFilters, selectedSort, pagination);

      const response = await typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-cancel", {
        query: serializePaginatedQueryValues(query),
        reqBody: {
          customerPurchaseStateIds: selectedResources.map(cps => cps.id),
        },
      });

      bulkActionCompleted.onCompleted();

      if (response.type === "success") {
        const failedSubscriptions: IFailedSubscriptionWithReason[] = response.body.failedSubscriptions;
        const updatedSubscriptions: IPurchaseStateWithCustomerRelationshipPaginatedResponse =
          response.body.newCustomerPurchaseState;

        if (failedSubscriptions.length) {
          openErrorModal({
            actionType: "cancel",
            failedSubscriptions,
          });
        }

        addToast("Subscription(s) canceled");

        return {
          data: updatedSubscriptions.data,
          pagination: {
            maxPages: updatedSubscriptions.totalPages,
            pageNumber: updatedSubscriptions.pageNumber + 1,
          },
        };
      }

      addToast("Error canceling subscription(s). Try to reload the page.");

      return {
        data: [],
        pagination: {
          maxPages: 1,
          pageNumber: 1,
        },
      };
    });
  }, [selectedResources, bulkActionCompleted]);

  if (!modalPayload || selectedResources.length === 0) {
    return null;
  }

  return (
    <ConfirmationWindow
      title={`Are you sure you want to cancel ${selectedResources.length} subscription(s)?`}
      onReject={closeModal}
      onConfirm={handleCancelation}
      confirmationText="Yes"
      rejectionText="No"
      open={isModalOpen}
    >
      It might take a few minutes to process this request.
    </ConfirmationWindow>
  );
};
