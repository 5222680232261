import React from "react";
import { ActivateConfirmationModal } from "./Activate";
import { BulkActionErrorModal } from "./Error";
import { PauseConfirmationModal } from "./Pause";
import { SetNextOrderDateConfirmationModal } from "./SetNextOrder";
import { OpenInNewTabConfirmationModal } from "./OpenInNewTab";
import { ViewRawSubscriptionContractModal } from "./ViewRawContract";
import { CancelConfirmationModal } from "./Cancel";

export const TableModals = () => {
  return (
    <React.Fragment>
      <PauseConfirmationModal />
      <ActivateConfirmationModal />
      <BulkActionErrorModal />
      <SetNextOrderDateConfirmationModal />
      <OpenInNewTabConfirmationModal />
      <ViewRawSubscriptionContractModal />
      <CancelConfirmationModal />
    </React.Fragment>
  );
};
