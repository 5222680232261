import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IDeserializedPaginatedQuery, serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";
import { navigateToSubscriptionDetails } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const bulkSetNextOrder = async (
  query: IDeserializedPaginatedQuery,
  cpsIds: string[],
  billingAdjustedDate: string
) => {
  return await typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-set-nextBillingDate", {
    query: serializePaginatedQueryValues(query),
    reqBody: {
      customerPurchaseStateIds: cpsIds,
      date: billingAdjustedDate,
    },
  });
};

export const openMultipleSubscriptionsInNewTabs = (cpsList: IPurchaseStateWithCustomerRelationship[]) => {
  if (cpsList.length > 20) {
    return;
  }

  for (const cps of cpsList) {
    cps.shopifyId && navigateToSubscriptionDetails(cps.shopifyId, true);
  }
};
