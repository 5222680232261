import { SetNextOrderDateModal } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/SubscriptionDetails/modals";
import { SubscriptionsTableAccess } from "../../../store";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { useSetNextOrderModalStore } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/SubscriptionDetails/modals/SetNextOrderDateModal/useSetNextOrderModalStore";
import { adjustToStoreBillingTime } from "@smartrr/shared/utils/dateUtils";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { generateQueryFromStore } from "../../../store/query";
import { useBulkActionErrorModal } from "../Error";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import React, { useCallback, useContext } from "react";
import { BulkActionCompletedContext } from "../../Table/bulkActionCompletedContext";
import { IFailedSubscriptionWithReason } from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";
import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { bulkSetNextOrder } from "../utils";
import { purchaseStateListContainAnchors } from "@smartrr/shared/utils/anchors";

export const SetNextOrderDateConfirmationModal = () => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const { isModalOpen } = useSetNextOrderModalStore();
  const tableActions = SubscriptionsTableAccess.useActions();
  const activeOrg = useActiveOrganizationSelector();
  const openErrorModal = useBulkActionErrorModal(state => state.openModal);
  const { addToast } = useToast();
  const bulkActionCompleted = useContext(BulkActionCompletedContext);

  const hasAnchors = purchaseStateListContainAnchors(selectedResources);

  const handleConfirmation = useCallback(
    (date: Date) => {
      const adjustedDate = adjustToStoreBillingTime(date, activeOrg?.billingTime, activeOrg?.billingTimezone);
      tableActions.data.update(async (currentData, selectedFilters, selectedSort, pagination) => {
        const query = generateQueryFromStore(selectedFilters, selectedSort, pagination);

        const response = await bulkSetNextOrder(
          query,
          selectedResources.map(cps => cps.id),
          adjustedDate
        );

        bulkActionCompleted.onCompleted();

        if (response.type === "success") {
          const failedSubscriptions: IFailedSubscriptionWithReason[] = response.body.failedSubscriptions;
          const updatedSubscriptions: IPurchaseStateWithCustomerRelationshipPaginatedResponse =
            response.body.newCustomerPurchaseState;

          if (failedSubscriptions.length) {
            openErrorModal({
              actionType: "set next date for",
              failedSubscriptions,
            });
          }

          addToast(
            `Next order date for ${selectedResources.length} subscription(s) updated to ${toViewDate(
              new Date(adjustedDate),
              { day: "numeric", month: "long", year: "numeric" }
            )}.`
          );

          return {
            data: updatedSubscriptions.data,
            pagination: {
              maxPages: updatedSubscriptions.totalPages,
              pageNumber: updatedSubscriptions.pageNumber + 1,
            },
          };
        }

        addToast("Error setting next order date. Try to reload the page.");

        return {
          data: [],
          pagination: {
            maxPages: 1,
            pageNumber: 1,
          },
        };
      });
    },
    [bulkActionCompleted, tableActions, addToast, openErrorModal]
  );

  if (!isModalOpen || selectedResources.length === 0) {
    return <React.Fragment />;
  }

  return (
    <SetNextOrderDateModal
      title={`Select the next order date for ${selectedResources.length > 1 ? "these" : "this"} ${pluralize(
        selectedResources.length,
        "subscription"
      )}`}
      bodyElement={<span>It might take a few minutes to process this request.</span>}
      initialISODate={new Date().toDateString()}
      onConfirm={handleConfirmation}
      allowSendNow={false}
    />
  );
};
