import { Button, DatePicker, LegacyCard, Popover } from "@shopify/polaris";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { SubscriptionsTableFilters } from "../../../store/filters";
import { DatePickerContainer } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { FilterContainer, SelectButton } from "./Containers";
import { DateTime } from "luxon";
import { OpenedSubfilterContext, toggleSubfilter } from "./openedSubfilterContext";
import { PreventClickthrough } from "./PreventClickthrough";

interface NavigationFilter_DateProps {
  dateFilter: "upcomingOrderDate" | "createdDate";
}
export const NavigationFilterDate = ({ dateFilter }: NavigationFilter_DateProps) => {
  const [{ month, year }, setDate] = useState({ month: new Date().getMonth(), year: new Date().getFullYear() });
  const handleMonthChange = useCallback((month: number, year: number) => setDate({ month, year }), []);
  const actions = SubscriptionsTableAccess.useActions();
  const activeOrg = useActiveOrganizationSelector();
  const orderValue = SubscriptionsTableAccess.useSelectedFilter(dateFilter);
  const filterInfo = SubscriptionsTableFilters.SubscriptionsTableFilters[dateFilter];
  const subfilterContext = useContext(OpenedSubfilterContext);

  const timezone = useMemo(() => {
    return activeOrg?.billingTimezone ?? "UTC";
  }, [activeOrg]);

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateRange = useMemo(() => {
    return orderValue
      ? {
          start: DateTime.fromMillis(+orderValue.value[0])
            .setZone(timezone)
            .setZone(localTimezone, { keepLocalTime: true })
            .toJSDate(),
          end: DateTime.fromMillis(+orderValue.value[1])
            .setZone(timezone)
            .setZone(localTimezone, { keepLocalTime: true })
            .toJSDate(),
        }
      : undefined;
  }, [orderValue, timezone, localTimezone]);

  const startDate = dateRange?.start
    ? DateTime.fromJSDate(dateRange.start).toLocaleString({
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    : "---";
  const endDate = dateRange?.end
    ? DateTime.fromJSDate(dateRange.end).toLocaleString({
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    : "---";

  const showSelectAValue = !dateRange?.start || !dateRange?.end;

  return (
    <Popover
      preventCloseOnChildOverlayClick
      autofocusTarget="first-node"
      active={subfilterContext.filterType === dateFilter}
      activator={
        <FilterContainer>
          <span>{filterInfo.title}:</span>
          <SelectButton>
            <Button disclosure="select" onClick={() => toggleSubfilter(subfilterContext, dateFilter)}>
              {showSelectAValue ? "Select a value" : `${startDate} - ${endDate}`}
            </Button>
          </SelectButton>
          <Button
            plain
            onClick={() => {
              actions.filter.remove(dateFilter);
            }}
          >
            X
          </Button>
        </FilterContainer>
      }
      onClose={() => subfilterContext.setFilter(null)}
    >
      <PreventClickthrough>
        <LegacyCard sectioned>
          <DatePickerContainer>
            <DatePicker
              allowRange
              multiMonth
              month={month}
              year={year}
              onChange={({ start, end }) => {
                const startDate = DateTime.fromJSDate(start).setZone(timezone, { keepLocalTime: true });
                const endDate = DateTime.fromJSDate(end).setZone(timezone, { keepLocalTime: true });

                const filter = [String(startDate.toMillis()), String(endDate.toMillis())];

                actions.filter.update([
                  {
                    filterType: dateFilter,
                    value: filter,
                  },
                ]);
              }}
              onMonthChange={handleMonthChange}
              selected={dateRange}
            />
          </DatePickerContainer>
        </LegacyCard>
      </PreventClickthrough>
    </Popover>
  );
};
