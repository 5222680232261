import { Text } from "@shopify/polaris";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import { viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import {
  ItemsPopover,
  getCPSStatusBadge,
  getFormattedUpcomingDate,
  getNextDelivery,
  openSubscriptionDetails,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { IdContainer } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { flatten, isInteger } from "lodash";

export const parseSubscriptionTableData = (
  purchaseStates: (IPurchaseStateWithCustomerRelationship & { cancellationReason?: string | undefined })[],
  sellingPlanGroups: ISmartrrSellingPlanGroup[],
  activeOrg: IOrganization | null
) => {
  return purchaseStates.map((purchaseState, index) => {
    const { initialSubmissionDate, custRel, purchaseStateStatus, shopifyId, stLineItems, sellingPlanId } =
      purchaseState;
    const createdDateWithZone = ISODateString.fromString(initialSubmissionDate).setZone(
      activeOrg?.billingTimezone
    );

    const nextDelivery = getNextDelivery(purchaseState, activeOrg);

    const nextOrderHasAddons =
      nextDelivery &&
      purchaseState.stLineItems.some(
        line => isInteger(line.skdIdx) && line.skdIdx === nextDelivery.indexFromScheduleStart
      );

    return {
      id: String(purchaseState.id),
      shopifyId: (
        <IdContainer onClick={() => openSubscriptionDetails(purchaseState)}>
          <Text variant="bodyMd" as="span" fontWeight="semibold">
            #{viewShopifyId(shopifyId)}
          </Text>
        </IdContainer>
      ),
      upcomingOrderDate: getFormattedUpcomingDate(purchaseState, activeOrg),
      emailOrName: `${custRel?.firstName} ${custRel?.lastName}`,
      email: custRel?.email,
      estimatedTotal:
        nextDelivery?.paymentMultipleDueOnDate === 0 && !nextOrderHasAddons
          ? "Prepaid"
          : getTotalDisplay(
              nextDelivery?.paymentMultipleDueOnDate ?? 1,
              purchaseState,
              nextDelivery?.indexFromScheduleStart ?? 0,
              true,
              nextDelivery?.indexFromNext
            ),
      items: (
        <ItemsPopover
          stLineItems={purchaseState.stLineItems.filter(
            item =>
              (item.isAddOn || item.isRedeemed ? item.skdIdx === nextDelivery?.indexFromScheduleStart : true) &&
              !item.isRedeemed
          )}
          activatorTitle={pluralize(
            stLineItems
              .filter(
                item =>
                  (item.isAddOn || item.isRedeemed
                    ? item.skdIdx === nextDelivery?.indexFromScheduleStart
                    : true) && !item.isRedeemed
              )
              .reduce((acc, prev) => (acc += prev.quantity), 0),
            "item"
          )}
          itemIndex={index}
        />
      ),
      status: getCPSStatusBadge(
        purchaseState.subProperties?.billingFailedMessage && purchaseStateStatus === "PAUSED"
          ? "FAILED"
          : purchaseStateStatus
      ),
      createdDate: createdDateWithZone
        ? createdDateWithZone.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
        : "---",
      sellingPlanId:
        flatten(sellingPlanGroups.map(spg => spg.sellingPlans)).find(sp => sp.shopifyId === sellingPlanId)
          ?.name ?? "---",
    };
  });
};
