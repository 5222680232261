import { SubscriptionsTableColumns } from "../../../store/columns";
import { IndexTable } from "@shopify/polaris";
import { parseSubscriptionTableData } from "../hooks/useParsedTableData/parseSubscriptionTableData";

export namespace SubscriptionTableCells {
  export type RowDataType = ReturnType<typeof parseSubscriptionTableData>[number];
  interface ColumnCellProps {
    row: RowDataType;
  }

  export const Cells: Record<SubscriptionsTableColumns.Column, (info: ColumnCellProps) => JSX.Element> = {
    id({ row }) {
      return <IndexTable.Cell>{row.id}</IndexTable.Cell>;
    },
    shopifyId({ row }) {
      return <IndexTable.Cell>{row.shopifyId}</IndexTable.Cell>;
    },
    upcomingOrderDate({ row }) {
      return <IndexTable.Cell>{row.upcomingOrderDate}</IndexTable.Cell>;
    },
    emailOrName({ row }) {
      return <IndexTable.Cell>{row.emailOrName}</IndexTable.Cell>;
    },
    email({ row }) {
      return <IndexTable.Cell>{row.email}</IndexTable.Cell>;
    },
    estimatedTotal({ row }) {
      return <IndexTable.Cell>{row.estimatedTotal}</IndexTable.Cell>;
    },
    items({ row }) {
      return <IndexTable.Cell>{row.items}</IndexTable.Cell>;
    },
    status({ row }) {
      return <IndexTable.Cell>{row.status}</IndexTable.Cell>;
    },
    createdDate({ row }) {
      return <IndexTable.Cell>{row.createdDate}</IndexTable.Cell>;
    },
    sellingPlanId({ row }) {
      return <IndexTable.Cell>{row.sellingPlanId}</IndexTable.Cell>;
    },
  };
}
