import styled from "styled-components";
import { SubscriptionsTableAccess } from "../../store";
import { Pagination } from "@shopify/polaris";

const PaginationContainer = styled.div`
  & > div {
    & > nav {
      > div {
        display: flex;
        justify-content: space-between;

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }
`;

export const SubscriptionTablePagination = () => {
  const pagination = SubscriptionsTableAccess.usePagination();
  const actions = SubscriptionsTableAccess.useActions();

  return (
    <PaginationContainer>
      <div className="polaris-pagination-wrapper">
        <Pagination
          hasNext={pagination.maxPages > pagination.pageNumber}
          hasPrevious={pagination.pageNumber > 1}
          label={`Showing ${pagination.pageNumber} of ${pagination.maxPages}`}
          onNext={() => actions.pagination.nextPage()}
          onPrevious={() => actions.pagination.previousPage()}
        ></Pagination>
      </div>
    </PaginationContainer>
  );
};
