import { getSellingPlanOptions } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { useContext, useMemo } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { Button, ChoiceList, LegacyCard, LegacyStack, Popover } from "@shopify/polaris";
import { FilterContainer, SelectButton } from "./Containers";
import { compact, truncate } from "lodash";
import { OpenedSubfilterContext, toggleSubfilter } from "./openedSubfilterContext";
import { PreventClickthrough } from "./PreventClickthrough";

export const NavigationFilterSellingPlan = () => {
  const sellingPlanGroups = SellingPlanGroupStoreAccess.useGroups();
  const actions = SubscriptionsTableAccess.useActions();
  const selectedSellingPlans = SubscriptionsTableAccess.useSelectedFilter("sellingPlanId");
  const subfilterContext = useContext(OpenedSubfilterContext);

  const sellingPlanOptions = useMemo(() => {
    return getSellingPlanOptions(sellingPlanGroups);
  }, [sellingPlanGroups]);

  const selectedPlanNames = useMemo(() => {
    const plans = selectedSellingPlans?.value ?? [];
    return compact(
      plans.map(s => {
        return sellingPlanOptions.find(option => option.value === s)?.label;
      })
    );
  }, [selectedSellingPlans, sellingPlanGroups]);

  return (
    <Popover
      preventCloseOnChildOverlayClick
      autofocusTarget="first-node"
      active={subfilterContext.filterType === "sellingPlanId"}
      activator={
        <FilterContainer>
          <span>Subscription program:</span>
          <SelectButton>
            <Button disclosure="select" onClick={() => toggleSubfilter(subfilterContext, "sellingPlanId")}>
              {selectedPlanNames.length
                ? truncate(selectedPlanNames.join(", "), { length: 25 })
                : "Select a value"}
            </Button>
          </SelectButton>
          <Button plain onClick={() => actions.filter.remove("sellingPlanId")}>
            X
          </Button>
        </FilterContainer>
      }
      onClose={() => subfilterContext.setFilter(null)}
      preferredPosition="below"
      zIndexOverride={10000}
    >
      <PreventClickthrough>
        <LegacyCard sectioned>
          <LegacyStack vertical spacing="extraTight">
            <ChoiceList
              title=""
              titleHidden
              allowMultiple
              selected={selectedSellingPlans?.value ?? []}
              choices={sellingPlanOptions}
              onChange={value => {
                actions.filter.update([
                  {
                    filterType: "sellingPlanId",
                    value,
                  },
                ]);
              }}
            />
          </LegacyStack>
        </LegacyCard>
      </PreventClickthrough>
    </Popover>
  );
};
