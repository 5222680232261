import React, { useCallback } from "react";
import { useExportToCSVAction } from "../Table/hooks/useBulkActions";
import { useMediaQuery } from "react-responsive";
import { Button, LegacyStack } from "@shopify/polaris";
import { ExportMinor, ExternalMinor } from "@shopify/polaris-icons";
import { SubscriptionsTableAccess } from "../../store";
import { ButtonBoldText } from "@vendor-app/app/_sharedComponents/ButtonBold";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

export const PageActions = React.memo(() => {
  const isMobile = useMediaQuery({
    maxWidth: 900,
  });
  const exportAction = useExportToCSVAction();
  const isLoading = SubscriptionsTableAccess.useIsLoading();
  const activeOrg = useActiveOrganizationSelector();
  const { addToast } = useToast();
  const isSuperUser = useIsSuperUserSelector();

  const viewSubscriptionInShopify = useCallback(() => {
    if (!activeOrg?.myShopifyDomain) {
      addToast("Can't open in Shopify");
      return;
    }
    frontEndTabOpen(
      `https://${activeOrg.myShopifyDomain}/admin/orders?tag=Subscription&inContextTimeframe=last_7_days`
    );
  }, [activeOrg]);

  const goToCreateSubscription = useCallback(() => {
    navigateWithShopInQuery("/admin/subscriptions/create");
  }, []);

  const goToCreateSubscriptionNew = useCallback(() => {
    navigateWithShopInQuery("/admin/subscriptions/createnew");
  }, []);

  return (
    <LegacyStack vertical={isMobile} alignment="center">
      <ButtonBoldText>
        <Button
          plain
          monochrome
          removeUnderline
          icon={ExportMinor}
          loading={isLoading}
          onClick={() => exportAction.onAction?.()}
        >
          Export to CSV
        </Button>
      </ButtonBoldText>
      <ButtonBoldText>
        <Button plain monochrome removeUnderline icon={ExternalMinor} onClick={() => viewSubscriptionInShopify()}>
          View in Shopify
        </Button>
      </ButtonBoldText>
      {isSuperUser ? (
        <Button primary onClick={goToCreateSubscription}>
          Create subscription
        </Button>
      ) : null}
      {isSuperUser ? <Button onClick={() => goToCreateSubscriptionNew()}>Create subscription NEW</Button> : null}
    </LegacyStack>
  );
});
