import { Button, LegacyCard, LegacyStack, Popover } from "@shopify/polaris";
import { SubscriptionsTableAccess } from "../../../store";
import { useState } from "react";
import { ArrowDownMinor, ArrowUpMinor, SortMinor } from "@shopify/polaris-icons";
import { SubscriptionsTableSorts } from "../../../store/sorts";
import styled from "styled-components";

const SortingValueContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;
    color: #202223;
    padding: var(--p-space-2);
  }
`;

const SortingTypeContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;

    .Polaris-Button__Content {
      color: #2c6ecb;

      .Polaris-Icon__Svg {
        fill: #2c6ecb;
      }
    }
  }
`;

const SortContainer = styled.div`
  overflow: hidden;

  .Polaris-LegacyCard__Section {
    padding: 8px !important;
  }

  & .Polaris-Button {
    justify-content: flex-start;
    margin: 0 !important;
    width: 100%;

    .Polaris-Button__Content {
      justify-content: flex-start;
    }
  }
`;

export const NavigationSorts = () => {
  const selectedSort = SubscriptionsTableAccess.useSelectedSort();
  const actions = SubscriptionsTableAccess.useActions();

  const [openSortPopover, setOpenSortPopover] = useState(false);

  return (
    <Popover
      fluidContent
      preventCloseOnChildOverlayClick
      active={openSortPopover}
      activator={
        <Button icon={SortMinor} onClick={() => setOpenSortPopover(!openSortPopover)}>
          Sort
        </Button>
      }
      onClose={() => setOpenSortPopover(false)}
    >
      <SortContainer>
        <LegacyCard>
          <LegacyCard.Section>
            <SortingValueContainer>
              <LegacyStack vertical spacing="extraTight">
                {SubscriptionsTableSorts.types.map(sort => (
                  <Button
                    key={sort}
                    removeUnderline
                    plain={selectedSort.sortType !== sort}
                    pressed={selectedSort.sortType === sort}
                    monochrome
                    onClick={() =>
                      actions.sort.select({
                        sortType: sort,
                        value: selectedSort.value,
                      })
                    }
                  >
                    {SubscriptionsTableSorts.SortInfo[sort].displayName}
                  </Button>
                ))}
              </LegacyStack>
            </SortingValueContainer>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SortingTypeContainer>
              <LegacyStack vertical spacing="extraTight">
                <Button
                  plain
                  monochrome={selectedSort.value !== "ASC"}
                  removeUnderline
                  pressed={selectedSort.value === "ASC"}
                  icon={ArrowUpMinor}
                  onClick={() =>
                    actions.sort.select({
                      sortType: selectedSort.sortType,
                      value: "ASC",
                    })
                  }
                >
                  Ascending
                </Button>
                <Button
                  plain
                  removeUnderline
                  pressed={selectedSort.value === "DESC"}
                  monochrome={selectedSort.value !== "DESC"}
                  icon={ArrowDownMinor}
                  onClick={() =>
                    actions.sort.select({
                      sortType: selectedSort.sortType,
                      value: "DESC",
                    })
                  }
                >
                  Descending
                </Button>
              </LegacyStack>
            </SortingTypeContainer>
          </LegacyCard.Section>
        </LegacyCard>
      </SortContainer>
    </Popover>
  );
};
