import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { flatten } from "lodash";

import { Options } from "@vendor-app/app/_sharedComponents/TableSearch/libs";

export function getSellingPlanOptions(sellingPlanGroups: ISmartrrSellingPlanGroup[]): Options {
  const sellingPlans = flatten(
    sellingPlanGroups.map(group => group.sellingPlans.map(sp => ({ ...sp, groupName: group.name })))
  );
  return sellingPlans.map(sellingPlan => ({
    label: `${sellingPlan.groupName} - ${sellingPlan.name}`,
    value: sellingPlan.shopifyId,
  }));
}
