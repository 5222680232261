import styled from "styled-components";

export const FilterContainer = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 178px 228px 20px;
  justify-content: space-between;
  min-height: 36px;
`;

export const SelectButton = styled.div`
  .Polaris-Button {
    width: 100%;
  }

  .Polaris-Button__Content {
    justify-content: space-between;
  }
`;
