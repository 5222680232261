import { useMemo } from "react";
import { SubscriptionsTableAccess } from "../../../../store";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { parseSubscriptionTableData } from "./parseSubscriptionTableData";

export const useParsedTableData = () => {
  const subscriptions = SubscriptionsTableAccess.useData();
  const sellingPlanGroups = SellingPlanGroupStoreAccess.useGroups();
  const activeOrg = useActiveOrganizationSelector();

  return useMemo(() => {
    return parseSubscriptionTableData(subscriptions, sellingPlanGroups, activeOrg);
  }, [subscriptions, sellingPlanGroups, activeOrg]);
};
