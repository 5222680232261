import { Icon, TextField } from "@shopify/polaris";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { DEFAULT_DEBOUNCE_TIME } from "@vendor-app/constants/table";
import { SearchMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

const SearchContainer = styled.div``;

export const NavigationSearch = () => {
  const actions = SubscriptionsTableAccess.useActions();
  const [search, setSearch] = useState("");

  const debouncedSetSearch = useCallback(
    debounce((search: string) => {
      actions.filter.update([
        {
          filterType: "emailOrNameOrId",
          value: [search],
        },
      ]);
    }, DEFAULT_DEBOUNCE_TIME * 2),
    []
  );

  const updateSearch = useCallback(
    (newSearch: string) => {
      setSearch(newSearch);
      debouncedSetSearch(newSearch);
    },
    [setSearch, debouncedSetSearch]
  );

  return (
    <SearchContainer>
      <TextField
        prefix={<Icon source={SearchMinor} />}
        autoComplete="off"
        label={"Email or Name or Id"}
        labelHidden
        value={search}
        onChange={updateSearch}
        placeholder={"Search by name/email/subscription id..."}
      />
    </SearchContainer>
  );
};
