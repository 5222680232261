import { IndexTable, Text, useIndexResourceState } from "@shopify/polaris";
import React, { useContext, useEffect, useMemo } from "react";
import { SubscriptionsTableAccess } from "../../store";
import { SubscriptionTableCells } from "./Cells";
import { ParsedTableDataContext } from "./parsedDataContext";
import { useParsedTableData } from "./hooks/useParsedTableData";
import { usePromotedBulkActions } from "./hooks/usePromotedBulkActions";
import { useBulkActions } from "./hooks/useBulkActions";
import { BulkActionCompletedContext } from "./bulkActionCompletedContext";
import { SelectionType } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { TableModals } from "../Modals";
import { NoUpcomingWrapper } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useIndexTableHeadingSortInfo } from "./hooks/useIndexTableHeadingSortInfo";
import { SubscriptionsTableColumns } from "../../store/columns";

interface TableRowProps {
  row: SubscriptionTableCells.RowDataType;
  position: number;
}

export const TableRow = ({ row, position }: TableRowProps) => {
  const enabledColumns = SubscriptionsTableAccess.useEnabledColumns();
  const isSelected = SubscriptionsTableAccess.useIsSelected(row.id);

  const cells = useMemo(() => {
    return enabledColumns.map(c => SubscriptionTableCells.Cells[c]);
  }, [enabledColumns]);

  return (
    <IndexTable.Row id={row.id} position={position} selected={isSelected}>
      {cells.map((Cell, key) => {
        return <Cell key={key} row={row} />;
      })}
    </IndexTable.Row>
  );
};

const TableRows = () => {
  const data = useContext(ParsedTableDataContext);

  return (
    <React.Fragment>
      {data.map((sub, key) => (
        <TableRow key={key} row={sub} position={key} />
      ))}
    </React.Fragment>
  );
};

export interface IndexTableSort {
  currentSortColumn: number | undefined;
  direction: "ascending" | "descending";
  sortableColumns: boolean[];
  onSort: (headingIndex: number, direction: "ascending" | "descending") => void;
}

const Table = () => {
  const data = useContext(ParsedTableDataContext);
  const headings = SubscriptionsTableAccess.useTableHeading(
    SubscriptionsTableColumns.SubscriptionsTableColumnInfo
  );
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(data);
  const promotedBulkActions = usePromotedBulkActions();
  const bulkActions = useBulkActions();
  const actions = SubscriptionsTableAccess.useActions();
  const isTableLoading = SubscriptionsTableAccess.useIsLoading();
  const sortInfo = useIndexTableHeadingSortInfo();

  useEffect(() => {
    actions.data.updateSelectionById(selectedResources);
  }, [selectedResources]);

  return (
    <BulkActionCompletedContext.Provider
      value={{
        onCompleted: () => handleSelectionChange(SelectionType.All, false),
      }}
    >
      <IndexTable
        loading={isTableLoading}
        selectable
        headings={headings}
        itemCount={data.length}
        selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
        onSelectionChange={handleSelectionChange}
        promotedBulkActions={promotedBulkActions}
        bulkActions={bulkActions}
        emptyState={
          <NoUpcomingWrapper>
            <Text variant="bodyLg" as="span" color="subdued">
              No subscriptions found
            </Text>
          </NoUpcomingWrapper>
        }
        resourceName={{
          singular: "subscription",
          plural: "subscriptions",
        }}
        sortColumnIndex={sortInfo.currentSortColumn}
        sortDirection={sortInfo.direction}
        onSort={sortInfo.onSort}
        sortable={sortInfo.sortableColumns}
      >
        <TableRows />
      </IndexTable>
      <TableModals />
    </BulkActionCompletedContext.Provider>
  );
};

export const SubscriptionTable = () => {
  const data = useParsedTableData();

  return (
    <ParsedTableDataContext.Provider value={data}>
      <Table />
    </ParsedTableDataContext.Provider>
  );
};
