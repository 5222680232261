import { LegacyCard, Page } from "@shopify/polaris";
import React, { useEffect } from "react";
import { SubscriptionsTableAccess } from "../../store";

import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { SubscriptionTable } from "../Table";
import { SubscriptionTableNavigation } from "../Navigation";
import { noawait } from "@smartrr/shared/utils/noawait";
import styled from "styled-components";
import { SubscriptionTablePagination } from "../Pagination";
import { PageActions } from "../PageActions";

const TablePageWrapper = styled.div`
  > .Polaris-Page {
    max-width: max(64rem, 75%);
  }

  .Polaris-IndexTable {
    background: white;
  }
`;

export const SubscriptionPage = () => {
  const actions = SubscriptionsTableAccess.useActions();
  const groupActions = SellingPlanGroupStoreAccess.useActions();

  useEffect(() => {
    actions.pagination.firstPage();
    noawait(async () => {
      await groupActions.fetchSellingPlanGroups(false);
    });
  }, []);

  return (
    <React.Fragment>
      <TablePageWrapper>
        <Page title="Subscriptions" primaryAction={<PageActions />}>
          <LegacyCard>
            <LegacyCard.Section flush>
              <SubscriptionTableNavigation />
              <SubscriptionTable />
            </LegacyCard.Section>
            <LegacyCard.Section subdued>
              <SubscriptionTablePagination />
            </LegacyCard.Section>
          </LegacyCard>
        </Page>
      </TablePageWrapper>
    </React.Fragment>
  );
};
