import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ProductQuerySearch } from "./store";
import { getVariantOptions } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { SubscriptionsTableAccess } from "@vendor-app/app/AdminRoute/AdminSubscriptionTableRoute/store";
import { Button, ChoiceList, Icon, LegacyCard, LegacyStack, Popover, TextField } from "@shopify/polaris";
import { FilterContainer, SelectButton } from "../Containers";
import { SearchMajor } from "@shopify/polaris-icons";
import { OpenedSubfilterContext, toggleSubfilter } from "../openedSubfilterContext";
import { PreventClickthrough } from "../PreventClickthrough";

export const NavigationFilterProducts = () => {
  const searchActions = ProductQuerySearch.access.useActions();
  const productList = ProductQuerySearch.access.useData();
  const previousSearch = ProductQuerySearch.access.usePreviousSearch();
  const searchTerm = ProductQuerySearch.access.useSearchTerm();
  const tableActions = SubscriptionsTableAccess.useActions();
  const items = SubscriptionsTableAccess.useSelectedFilter("items") ?? {
    filterType: "items",
    value: [],
  };
  const [searchText, setSearchText] = useState(searchTerm);
  const subfilterContext = useContext(OpenedSubfilterContext);

  useEffect(() => {
    if (previousSearch) {
      searchActions.refetch(previousSearch);
    } else {
      searchActions.refetch({
        page: 1,
        search: "",
        dynamic: { perPage: 10 },
      });
    }
  }, []);

  const debouncedSearch = useCallback((search: string) => {
    searchActions.search.update(search);
  }, []);

  const variantOptions = useMemo(() => {
    return getVariantOptions(productList);
  }, [productList]);

  const updateSearch = useCallback((newSearch: string) => {
    setSearchText(newSearch);
    debouncedSearch(newSearch);
  }, []);

  return (
    <Popover
      preventCloseOnChildOverlayClick
      autofocusTarget="first-node"
      active={subfilterContext.filterType === "items"}
      activator={
        <FilterContainer>
          <span>Items:</span>
          <SelectButton className="smartrr-table-filter-items">
            <Button disclosure="select" onClick={() => toggleSubfilter(subfilterContext, "items")}>
              {items.value.length ? `${items.value.length} item(s)` : "Select a value"}
            </Button>
          </SelectButton>
          <Button plain onClick={() => tableActions.filter.remove("items")}>
            X
          </Button>
        </FilterContainer>
      }
      onClose={() => subfilterContext.setFilter(null)}
      preferredPosition="below"
      zIndexOverride={10000}
    >
      <PreventClickthrough>
        <LegacyCard sectioned>
          <LegacyStack vertical spacing="extraTight">
            <TextField
              autoComplete="off"
              label="Searchbar"
              labelHidden
              type="text"
              value={searchText}
              onChange={s => updateSearch(s)}
              placeholder="Search"
              prefix={<Icon source={SearchMajor}></Icon>}
            />
            <ChoiceList
              title=""
              titleHidden
              allowMultiple
              selected={items.value}
              choices={variantOptions}
              onChange={value => {
                tableActions.filter.update([
                  {
                    filterType: "items",
                    value,
                  },
                ]);
              }}
            />
          </LegacyStack>
        </LegacyCard>
      </PreventClickthrough>
    </Popover>
  );
};
