import { useContext } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { Button, ChoiceList, LegacyCard, LegacyStack, Popover } from "@shopify/polaris";
import { FilterContainer, SelectButton } from "./Containers";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { truncate } from "lodash";
import { OpenedSubfilterContext, toggleSubfilter } from "./openedSubfilterContext";
import { PreventClickthrough } from "./PreventClickthrough";

const SubscriptionStatusChoiceList = [
  { label: "Active", value: SubscriptionContractSubscriptionStatus.Active },
  { label: "Paused", value: SubscriptionContractSubscriptionStatus.Paused },
  { label: "Canceled", value: SubscriptionContractSubscriptionStatus.Cancelled },
  { label: "Expired", value: SubscriptionContractSubscriptionStatus.Expired },
];

export const NavigationFilterStatus = () => {
  const actions = SubscriptionsTableAccess.useActions();
  const status = SubscriptionsTableAccess.useSelectedFilter("status");
  const subfilterContext = useContext(OpenedSubfilterContext);

  return (
    <Popover
      preventCloseOnChildOverlayClick
      autofocusTarget="first-node"
      active={subfilterContext.filterType === "status"}
      activator={
        <FilterContainer>
          <span onClick={() => toggleSubfilter(subfilterContext, "status")}>Status:</span>
          <SelectButton>
            <Button disclosure="select" onClick={() => toggleSubfilter(subfilterContext, "status")}>
              {status?.value.length ? truncate(status.value.join(", "), { length: 25 }) : "Select a value"}
            </Button>
          </SelectButton>
          <Button plain onClick={() => actions.filter.remove("status")}>
            X
          </Button>
        </FilterContainer>
      }
      onClose={() => subfilterContext.setFilter(null)}
    >
      <PreventClickthrough>
        <LegacyCard sectioned>
          <LegacyStack vertical spacing="extraTight">
            <ChoiceList
              title=""
              titleHidden
              allowMultiple
              selected={status?.value ?? []}
              choices={SubscriptionStatusChoiceList}
              onChange={value => {
                actions.filter.update([
                  {
                    filterType: "status",
                    value,
                  },
                ]);
              }}
            />
          </LegacyStack>
        </LegacyCard>
      </PreventClickthrough>
    </Popover>
  );
};
