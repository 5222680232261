import React, { MouseEvent, useCallback } from "react";

interface PreventClickthroughProps {
  children: React.ReactNode | React.ReactNode[];
}

export const PreventClickthrough = ({ children }: PreventClickthroughProps) => {
  const stopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return <div onClick={stopPropagation}>{children}</div>;
};
