import React from "react";
import { SubscriptionsTableFilters } from "../../../store/filters";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";

interface OpenedSubfilter {
  filterType: SubscriptionsTableFilters.Filter | null;
  setFilter: (filterType: SubscriptionsTableFilters.Filter | null) => void;
}

export const OpenedSubfilterContext = React.createContext<OpenedSubfilter>({
  filterType: null,
  setFilter: NO_OP_CALLBACK,
});

export const toggleSubfilter = (current: OpenedSubfilter, filterToToggle: SubscriptionsTableFilters.Filter) => {
  if (current.filterType === filterToToggle) {
    current.setFilter(null);
  } else {
    current.setFilter(filterToToggle);
  }
};
