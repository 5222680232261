import { useMemo } from "react";
import { SubscriptionsTableAccess } from "../../../store";
import { BulkAction } from "@shopify/polaris/build/ts/src/components/BulkActions";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { usePauseConfirmationModal } from "../../Modals/Pause";
import { useActivateConfirmationModal } from "../../Modals/Activate";
import { useSetNextOrderModalStore } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/SubscriptionDetails/modals/SetNextOrderDateModal/useSetNextOrderModalStore";
import { useOpenInNewTabConfirmationModalStore } from "../../Modals/OpenInNewTab";
import { openMultipleSubscriptionsInNewTabs } from "../../Modals/utils";
import { compact } from "lodash";
import { useViewRawContractModalStore } from "../../Modals/ViewRawContract";

const useBulkPauseAction = (): BulkAction | null => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const areAllSelectedSubscriptionsActive = useMemo(() => {
    return !selectedResources.some(
      resource => resource.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active
    );
  }, [selectedResources]);
  const openPauseModal = usePauseConfirmationModal(state => state.openModal);

  return useMemo(() => {
    if (!areAllSelectedSubscriptionsActive) {
      return null;
    }

    return {
      content: "Pause",
      onAction: () => openPauseModal({}),
    };
  }, [selectedResources, areAllSelectedSubscriptionsActive]);
};

const useBulkActivateAction = (): BulkAction | null => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const openActivateModal = useActivateConfirmationModal(state => state.openModal);

  const areAllSelectedSubscriptionsPaused = useMemo(() => {
    return !selectedResources.some(
      resource => resource.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Paused
    );
  }, [selectedResources]);

  return useMemo(() => {
    if (!areAllSelectedSubscriptionsPaused) {
      return null;
    }

    return {
      content: "Activate",
      onAction: () => openActivateModal({}),
    };
  }, [selectedResources, areAllSelectedSubscriptionsPaused]);
};

const useSetNextOrderDateAction = (): BulkAction | null => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const areAllSelectedSubscriptionsActive = useMemo(() => {
    return !selectedResources.some(
      resource => resource.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active
    );
  }, [selectedResources]);

  const openNextOrderModal = useSetNextOrderModalStore(state => state.openModal);

  return useMemo(() => {
    if (!areAllSelectedSubscriptionsActive) {
      return null;
    }
    return {
      content: "Set new order date",
      onAction: () => openNextOrderModal({}),
    };
  }, [selectedResources, areAllSelectedSubscriptionsActive]);
};

const useOpenInNewTabAction = (): BulkAction => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();

  const openInNewTabModal = useOpenInNewTabConfirmationModalStore(state => state.openModal);

  return useMemo(() => {
    return {
      content: "Open in new tab",
      onAction() {
        if (selectedResources.length > 3) {
          openInNewTabModal({});
        } else {
          openMultipleSubscriptionsInNewTabs(selectedResources);
        }
      },
    };
  }, [selectedResources]);
};

const useViewRawContractAction = (): BulkAction | null => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const isSuperUser = useIsSuperUserSelector();
  const openViewRawContract = useViewRawContractModalStore(state => state.openModal);

  return useMemo(() => {
    if (!isSuperUser || selectedResources.length !== 1) {
      return null;
    }
    return {
      content: "View raw subscription contract",
      onAction: () => openViewRawContract({}),
    };
  }, [selectedResources, isSuperUser]);
};

export const usePromotedBulkActions = () => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const actions = SubscriptionsTableAccess.useActions();
  const isSuperUser = useIsSuperUserSelector();

  const pauseAction = useBulkPauseAction();
  const activateAction = useBulkActivateAction();
  const nextOrderAction = useSetNextOrderDateAction();
  const openInNewTabAction = useOpenInNewTabAction();
  const viewRawContract = useViewRawContractAction();

  return useMemo(() => {
    return compact([pauseAction, activateAction, nextOrderAction, openInNewTabAction, viewRawContract]);
  }, [actions, selectedResources, isSuperUser, pauseAction, activateAction]);
};
