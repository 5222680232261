import { SearchFormStoreFactory } from "@smartrr/shared/components/SearchFormStoreFactory";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

const PRODUCTS_PER_PAGE = 10;
export const ProductQuerySearch = SearchFormStoreFactory<
  {
    perPage: number;
  },
  IPurchasable
>(
  { perPage: PRODUCTS_PER_PAGE },
  async ({ perPage }, page, search) => {
    const productsResponse = await typedFrontendVendorApi.getReq("/purchasable", {
      query: serializePaginatedQueryValues({
        pageSize: perPage,
        pageNumber: page - 1,
        filterLike: {
          purchasableName: search,
        },
      }),
    });

    if (productsResponse.type === "success") {
      return {
        count: productsResponse.body.totalCount,
        data: productsResponse.body.data,
      };
    }

    return {
      count: 0,
      data: [],
    };
  },
  {
    scrollType: "infinite",
    getId: p => p.shopifyId ?? "",
  }
);
