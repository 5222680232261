import React, { useCallback } from "react";

import styled from "styled-components";
import { NavigationSearch } from "./Search";
import { NavigationFilters } from "./Filters";
import { NavigationSorts } from "./Sorts";
import { GenericInteractiveTableColumn } from "@vendor-app/app/_sharedComponents/InteractiveDataTable/components/Columns";
import { SubscriptionsTableAccess } from "../../store";
import { SubscriptionsTableColumns } from "../../store/columns";
import { z } from "zod";

const NavigationContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 15px;
  background: white;

  > div:first-of-type {
    flex: 1;
  }
`;

const NavigationColumn = () => {
  const validateCallback = useCallback((data: string[]): SubscriptionsTableColumns.Column[] => {
    const values = z.array(SubscriptionsTableColumns.typeSchema).safeParse(data);
    if (values.success) {
      return values.data;
    }
    return [];
  }, []);

  return (
    <GenericInteractiveTableColumn
      storeAccess={SubscriptionsTableAccess}
      columnInfo={SubscriptionsTableColumns.SubscriptionsTableColumnInfo}
      shownColumns={SubscriptionsTableColumns.shownTableColumns}
      validateSelectedColumns={validateCallback}
    />
  );
};

export const SubscriptionTableNavigation = () => {
  return (
    <NavigationContainer>
      <NavigationSearch />
      <NavigationFilters />
      <NavigationColumn />
      <NavigationSorts />
    </NavigationContainer>
  );
};
