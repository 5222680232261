import { Modal, Spinner } from "@shopify/polaris";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { SubscriptionsTableAccess } from "../../../store";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useEffect, useState } from "react";
import {
  SubscriptionContractDiscountFromQuery,
  SubscriptionContractFromQuery,
  SubscriptionLineItemFromQuery,
} from "@smartrr/shared/shopifyGraphQL/subscriptionContracts";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { Box } from "@smartrr/shared/components/primitives";
import { getRawContractData } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useApolloClient } from "@apollo/client";
import { noawait } from "@smartrr/shared/utils/noawait";

export const useViewRawContractModalStore = createSmartrrModal<{}>();

export const ViewRawSubscriptionContractModal = () => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();

  if (selectedResources.length !== 1) {
    return null;
  }

  return <ViewSpecificSubscriptionContract cps={selectedResources[0]} />;
};

interface ViewSpecificSubscriptionContractProps {
  cps: IPurchaseStateWithCustomerRelationship;
}

interface RawContractInformation {
  contract: SubscriptionContractFromQuery & { lines: SubscriptionLineItemFromQuery[] } & {
    discounts: SubscriptionContractDiscountFromQuery[];
  };
}

const ViewSpecificSubscriptionContract = ({ cps }: ViewSpecificSubscriptionContractProps) => {
  const { isModalOpen, closeModal } = useViewRawContractModalStore();
  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const [rawContractData, setRawContractData] = useState<RawContractInformation | null>(null);
  const [rawContractLoading, setRawContractLoading] = useState(false);

  const viewRawContractData = async (shopifyId: string | undefined) => {
    if (!shopifyId) {
      return;
    }

    setRawContractLoading(true);
    await getRawContractData(shopifyId, apolloClient)
      .then(({ lines, discounts, contract }) => {
        setRawContractData({
          contract: {
            ...contract,
            lines,
            discounts,
          },
        });
      })
      .catch(error => {
        addToast(error);
      });
    setRawContractLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      noawait(async () => await viewRawContractData(cps.shopifyId));
    }
  }, [cps, isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      title="Shopify data"
      open={isModalOpen}
      onClose={() => {
        closeModal();
        setRawContractData(null);
      }}
      primaryAction={{
        content: "Close",
        onAction() {
          closeModal();
          setRawContractData(null);
        },
      }}
      secondaryActions={[
        {
          content: "Copy",
          onAction() {
            copyToClipboard(JSON.stringify(rawContractData, null, 2), `Data copied to clipboard`, addToast);
          },
        },
      ]}
    >
      <Box p={1}>
        {rawContractLoading ? (
          <Box justifyContent="center" alignItems="center" style={{ width: "100%" }}>
            <Spinner />
          </Box>
        ) : (
          <Box>
            <pre style={{ fontFamily: "monospace", margin: 0, whiteSpace: "pre-wrap" }}>
              {JSON.stringify(rawContractData, null, 2)}
            </pre>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
