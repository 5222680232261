import { Button, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { SubscriptionsTableAccess } from "../../../../store";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_TIME } from "@vendor-app/constants/table";
import { FilterContainer } from "../Containers";

export const NavigationFilterAmount = () => {
  const actions = SubscriptionsTableAccess.useActions();
  const [value, setValue] = useState("");

  const filteredAmount = SubscriptionsTableAccess.useSelectedFilter("estimatedTotal");

  useEffect(() => {
    if (filteredAmount) {
      setValue(filteredAmount.value.join(";"));
    }
  }, []);

  const debouncedFilterUpdate = useCallback(
    debounce((search: string) => {
      const values = search.split(";").filter(v => v !== "");
      if (values.length) {
        actions.filter.update([
          {
            filterType: "estimatedTotal",
            value: values,
          },
        ]);
      } else {
        actions.filter.remove("estimatedTotal");
      }
    }, DEFAULT_DEBOUNCE_TIME * 2),
    []
  );

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);
      debouncedFilterUpdate(value);
    },
    [setValue, debouncedFilterUpdate]
  );

  return (
    <FilterContainer>
      <span>Est. total:</span>
      <TextField
        autoComplete="off"
        label="Estimated Total"
        labelHidden
        value={value}
        placeholder="Select a value"
        onChange={s => updateValue(s)}
      ></TextField>
      <Button plain onClick={() => updateValue("")}>
        X
      </Button>
    </FilterContainer>
  );
};
