import { useMemo } from "react";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import { SubscriptionsTableAccess } from "../../../store";
import { BulkAction } from "@shopify/polaris/build/ts/src/components/BulkActions";
import { mapPurchaseStatesWithDelay } from "@vendor-app/utils/mapSubscriptionWithDelay";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { createAndDownloadCsv } from "@vendor-app/utils/createAndDownloadCsv";
import { DateTime } from "luxon";
import { useCancelConfirmationModal } from "../../Modals/Cancel";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { compact } from "lodash";
import { IPurchaseStateCSV } from "@smartrr/shared/entities/PurchaseState";
import { exportPurchaseStatesToCsvRows } from "@vendor-app/utils/exportPurchaseStatesToCsvRows";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import { SubscriptionsTableFilters } from "../../../store/filters";

export const useExportToCSVAction = (): BulkAction => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const tableActions = SubscriptionsTableAccess.useActions();
  const selectedFilters = SubscriptionsTableAccess.useSelectedFilters();
  const activeOrg = useActiveOrganizationSelector();

  return useMemo(() => {
    return {
      content: "Export to CSV",
      onAction() {
        void tableActions.whileLoading(async () => {
          let rows: IPurchaseStateCSV[] = [];

          if (selectedResources.length) {
            rows = await mapPurchaseStatesWithDelay(selectedResources, activeOrg);
          } else {
            const query: IDeserializedPaginatedQuery = {
              pageNumber: 0,
              /**
               * Set by filters
               */
              pageSize: 0,
            };

            for (const filter of selectedFilters) {
              const chosenFilter = SubscriptionsTableFilters.SubscriptionsTableFilters[filter.filterType];
              if (chosenFilter) {
                chosenFilter.addToQuery(query, filter.value);
              }
            }

            rows = await exportPurchaseStatesToCsvRows(activeOrg, undefined, query);
          }

          const formattedDate = DateTime.now().toFormat("yyyy_MM_dd");
          createAndDownloadCsv(rows, `subscriptions_export_${formattedDate}`);
        });
      },
    };
  }, [selectedResources, activeOrg, selectedFilters]);
};

const useCancelBulkAction = (): BulkAction | null => {
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const openCancelationModal = useCancelConfirmationModal(state => state.openModal);

  const areAllSelectedSubscriptionsCancellable = !selectedResources.some(
    cps =>
      cps.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Active &&
      cps.purchaseStateStatus !== SubscriptionContractSubscriptionStatus.Paused
  );

  return useMemo(() => {
    if (!areAllSelectedSubscriptionsCancellable) {
      return null;
    }

    return {
      content: `Cancel ${pluralizeForPrefix(selectedResources.length, "subscription")}`,
      onAction: () => openCancelationModal({}),
      destructive: true,
    };
  }, [selectedResources]);
};

export const useBulkActions = (): BulkAction[] => {
  const exportToCSVAction = useExportToCSVAction();
  const cancelAction = useCancelBulkAction();

  return useMemo(() => {
    return compact([exportToCSVAction, cancelAction]);
  }, [exportToCSVAction, cancelAction]);
};
