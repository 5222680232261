import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { SubscriptionsTableAccess } from "../../../store";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import React, { useCallback, useContext } from "react";
import { BulkActionCompletedContext } from "../../Table/bulkActionCompletedContext";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { openMultipleSubscriptionsInNewTabs } from "../utils";

export const useOpenInNewTabConfirmationModalStore = createSmartrrModal<{}>();

export const OpenInNewTabConfirmationModal = () => {
  const { isModalOpen, closeModal } = useOpenInNewTabConfirmationModalStore();
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const bulkActionCompleted = useContext(BulkActionCompletedContext);
  const { addToast } = useToast();

  const handleOpenInNewTabConfirmation = useCallback(() => {
    if (selectedResources.length > 20) {
      addToast("Too many subscriptions selected");
      closeModal();
      return;
    }

    openMultipleSubscriptionsInNewTabs(selectedResources);

    closeModal();
  }, [selectedResources, bulkActionCompleted]);

  if (!isModalOpen || selectedResources.length === 0) {
    return <React.Fragment />;
  }

  return (
    <ConfirmationWindow
      title={`Are you sure you want to open ${selectedResources.length} subscription(s) in new tabs?`}
      onReject={closeModal}
      onConfirm={handleOpenInNewTabConfirmation}
      confirmationText="Yes"
      rejectionText="No"
      open={isModalOpen}
    />
  );
};
