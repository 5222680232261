import {
  FailedPurchaseStateAction,
  IFailedSubscriptionWithReason,
  SubscriptionErrorModal,
} from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import React from "react";

export const useBulkActionErrorModal = createSmartrrModal<{
  failedSubscriptions: IFailedSubscriptionWithReason[];
  actionType: FailedPurchaseStateAction;
}>();

export const BulkActionErrorModal = () => {
  const { isModalOpen, modalPayload, closeModal } = useBulkActionErrorModal();

  if (!isModalOpen || !modalPayload) {
    return <React.Fragment />;
  }

  return (
    <SubscriptionErrorModal
      errorAction={modalPayload.actionType}
      open={isModalOpen}
      closeModal={closeModal}
      failedPurchaseStates={modalPayload.failedSubscriptions}
    />
  );
};
