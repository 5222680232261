import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { adjustToStoreBillingTime, setDateTimeToLastSecond } from "@smartrr/shared/utils/dateUtils";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { SubscriptionsTableAccess } from "../../../store";
import { generateQueryFromStore } from "../../../store/query";
import { serializePaginatedQueryValues } from "@smartrr/shared/utils/paginatedQuery";
import { IFailedSubscriptionWithReason } from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";
import { useBulkActionErrorModal } from "../Error";
import { useCallback, useContext, useMemo } from "react";
import moment from "moment";
import { DatePickerModal } from "@vendor-app/app/_sharedComponents/DatePickerModal";
import { Banner } from "@shopify/polaris";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { BulkActionCompletedContext } from "../../Table/bulkActionCompletedContext";

export const usePauseConfirmationModal = createSmartrrModal<{}>();

const PAUSE_DATE_MAX_MONTHS_RANGE = 6;

export const PauseConfirmationModal = () => {
  const { isModalOpen, closeModal, modalPayload } = usePauseConfirmationModal();
  const activeOrg = useActiveOrganizationSelector();
  const tableActions = SubscriptionsTableAccess.useActions();
  const selectedResources = SubscriptionsTableAccess.useSelectedData();
  const openErrorModal = useBulkActionErrorModal(state => state.openModal);
  const { addToast } = useToast();
  const bulkActionCompleted = useContext(BulkActionCompletedContext);

  const initialUnpauseDate = useMemo(() => {
    return new Date();
  }, []);

  const disabledDatesBefore = useMemo(() => setDateTimeToLastSecond(new Date()), []);

  const disabledDatesAfter = useMemo(
    () => moment(initialUnpauseDate).add(PAUSE_DATE_MAX_MONTHS_RANGE, "M").toDate(),
    [initialUnpauseDate]
  );

  const handlePauseConfirmation = useCallback(
    (date: Date) => {
      const adjustedDate = adjustToStoreBillingTime(date, activeOrg?.billingTime, activeOrg?.billingTimezone);
      tableActions.data.update(async (currentData, selectedFilters, selectedSort, pagination) => {
        closeModal();
        const query = generateQueryFromStore(selectedFilters, selectedSort, pagination);

        const response = await typedFrontendVendorApi.putReq("/customer/purchase-state/bulk-pause", {
          query: serializePaginatedQueryValues(query),
          reqBody: {
            customerPurchaseStateIds: selectedResources.map(cps => cps.id),
            unpauseDate: adjustedDate,
          },
        });

        bulkActionCompleted.onCompleted();

        if (response.type === "success") {
          const failedSubscriptions: IFailedSubscriptionWithReason[] = response.body.failedSubscriptions;
          const updatedSubscriptions: IPurchaseStateWithCustomerRelationshipPaginatedResponse =
            response.body.newCustomerPurchaseState;

          if (failedSubscriptions.length) {
            openErrorModal({
              actionType: "pause",
              failedSubscriptions,
            });
          }

          addToast(
            `${selectedResources.length} subscription(s) paused. Auto-resume set for ${toViewDate(
              new Date(adjustedDate),
              { day: "numeric", month: "long", year: "numeric" }
            )}.`
          );

          return {
            data: updatedSubscriptions.data,
            pagination: {
              maxPages: updatedSubscriptions.totalPages,
              pageNumber: updatedSubscriptions.pageNumber + 1,
            },
          };
        }

        addToast("Error pausing subscription(s). Try to reload the page.");

        return {
          data: [],
          pagination: {
            maxPages: 1,
            pageNumber: 1,
          },
        };
      });
    },
    [selectedResources, bulkActionCompleted, activeOrg]
  );

  if (!modalPayload || selectedResources.length === 0) {
    return null;
  }

  return (
    <DatePickerModal
      open={isModalOpen}
      key={`Pause ${pluralize(selectedResources.length, "subscription")}`}
      title={`Pause ${pluralize(selectedResources.length, "subscription")}`}
      bodyElement={
        <Banner status="info">
          {`Please select a future date for ${pluralize(
            selectedResources.length,
            "subscription"
          )} to auto-resume. The customer will be notified by email one week in advance of the unpause date.`}
        </Banner>
      }
      disabledDatesBefore={disabledDatesBefore}
      disabledDatesAfter={disabledDatesAfter}
      initialDate={moment(initialUnpauseDate).add(1, "M").toDate()}
      onClose={closeModal}
      onConfirm={handlePauseConfirmation}
    />
  );
};
